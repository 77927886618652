import React from "react"
import "../styles/long1.css"
import "../styles/mysterybox.css"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"

const IndexPage = () => (
  <div className="grid-container mysterybox mb-thankyou">
    <Helmet>
      {/* <!--START Lightbox Javascript--> */}
      <script async type='text/javascript' src='https://www.lightboxcdn.com/vendor/8d9917f0-0003-4ba7-baf5-0fb1897cc287/lightbox_inline.js'></script>
    </Helmet>

    <div className="header">
      <div className="header1">
        <div className="logo-div go-logo-div">
          <a aria-current="page" className="logo" href="/">
            <div className="driverprofile-logo"></div>
          </a>
        </div>
      </div>
      <a href="https://www.texpresslanes.com/" target="_blank">
        <div className="header2">What are TEXpress Lanes</div>
      </a>
      <a
        href="https://www.texpresslanes.com/maps/texpress-lanes-map"
        target="_blank"
      >
        <div className="header3">Where are TEXpress Lanes</div>
      </a>
      <a
        href="https://www.texpresslanes.com/navigating-texpress-lanes/how-to-use"
        target="_blank"
      >
        <div className="header4">How to Use TEXpress Lanes</div>
      </a>
    </div>
    


    <div className="left_space"></div>
    <div className="short1-main">
      <div className="mb-bg">
        <div className="mb-headSection max-width-contain">
          <div className="mb-leftSection">
            <p className="mb-header">
            The mystery discount entry period has ended.
            </p>
            <p className="mb-subtext">
            {/* Thanks to all who entered. Enjoy your toll savings in the month of August on the LBJ, NTE & NTE 35W TEXpress Lanes! */}

            Thanks to all who entered. Enjoy your toll savings starting on January 8th, 2024 through February 5th, 2024 on the LBJ, NTE & NTE 35W TEXpress Lanes!

            </p>
               </div>
     

        </div>
      </div>



    </div>
  </div>
)

export default IndexPage
